import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ProductNotification } from '~/features/ProductDetail';
import { useTranslations } from '~/services';
import { Gutter } from '~/shared/components/Gutter';
import { MaxWidth } from '~/shared/components/MaxWidth';

export function ClientErrorCatcher({ children }: { children: React.ReactNode }) {
    return <ErrorBoundary FallbackComponent={Error}>{children}</ErrorBoundary>;
}

const FALLBACK_ERROR_MESSAGE =
    'An unknown error occurred. Try reloading the site. We apologize for the inconvenience.';

function Error({ error }: FallbackProps) {
    const { translate } = useTranslations();

    return (
        <MaxWidth>
            <Gutter>
                <ProductNotification
                    variant="high"
                    title={translate('generic.siteError') || FALLBACK_ERROR_MESSAGE}
                    content={error?.message}
                />
            </Gutter>
        </MaxWidth>
    );
}
