import { QueryClient } from '@tanstack/react-query';
import * as Services from '~/services';

type Arguments = {
    accessToken: string | undefined;
    customerId: string | null;
    locale: string | undefined;
    queryClient: QueryClient;
    slug: string[] | undefined;
};

export async function prefetchAuthenticatedData({
    accessToken,
    customerId,
    locale,
    queryClient,
    slug,
}: Arguments) {
    const asPath = '/mit-fog/' + (slug ?? []).join('/');

    const prefetch: Promise<(() => undefined) | undefined | unknown>[] = [
        Services.prefetchFrame({ locale, queryClient }), // Prefetch frame data across all pages
        Services.prefetchFrameNavigation({ locale, queryClient }), // Prefetch navigation data across all pages
        Services.prefetchTranslations({ locale, queryClient }), // Prefetch translations across all pages];
        Services.prefetchPage({ locale, queryClient, asPath, accessToken, customerId }), // Page specific data
        Services.prefetchMitFogNavigation({ locale, queryClient, accessToken, customerId }), // Navigation specific data
    ];

    // Wait for all prefetch to complete
    await Promise.all(prefetch);
}
