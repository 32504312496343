import { QueryClient } from '@tanstack/react-query';
import { ShopTypes, UmbracoTypes } from '~/lib/data-contract';
import * as Services from '~/services';

export async function prefetchStaticData({ queryClient, locale, slug }: DataResolver) {
    let notFound = false;
    const asPath = '/' + (slug ?? []).join('/');

    const prefetch: Promise<(() => undefined) | undefined | unknown>[] = [
        Services.prefetchFrame({ locale, queryClient }), // Prefetch frame data across all pages
        Services.prefetchFrameNavigation({ locale, queryClient }), // Prefetch navigation data across all pages
        Services.prefetchTranslations({ locale, queryClient }), // Prefetch translations across all pages];
    ];

    const page = await Services.prefetchPage({ locale, queryClient, asPath, customerId: null }); // Page specific data

    if ('pageElements' in page) {
        // Prefetch products based on page elements
        page.pageElements?.forEach(async (block) => {
            switch (block.type) {
                case UmbracoTypes.ModuleTypes.IM30PopularProductCarouselModule: {
                    prefetch.push(Services.prefetchPopularEntities({ queryClient }));
                    break;
                }

                case UmbracoTypes.ModuleTypes.IM30CampaignProductCarouselModule: {
                    const { amountOfProducts, campaignID, sorting, ascendingOrder } =
                        //@ts-ignore
                        //Note: Look into this
                        block as UmbracoTypes.IM30CampaignProductCarouselModule;
                    prefetch.push(
                        Services.prefetchCampaignEntities({
                            queryClient,
                            amountOfProducts,
                            campaignID,
                            sorting,
                            ascendingOrder,
                        }),
                    );
                    break;
                }

                case UmbracoTypes.ModuleTypes.IM30BrandProductCarouselModule: {
                    //@ts-ignore
                    //Note: Look into this
                    const { brand } = block as UmbracoTypes.IM30BrandProductCarouselModule;
                    prefetch.push(Services.prefetchBrandEntities({ queryClient, brand }));
                    break;
                }

                default:
                    break;
            }
        });
    }

    // Prefetch data based on the page type
    switch (page?.type) {
        case UmbracoTypes.PageTypes.IP10SearchPage: {
            prefetch.push(Services.prefetchFilterDefaults({ queryClient }));
            break;
        }

        case UmbracoTypes.PageTypes.IP11PopularProductsPage: {
            prefetch.push(Services.prefetchPopularEntities({ queryClient, amount: 60 }));
            break;
        }

        case UmbracoTypes.PageTypes.IP14BrandOverviewPage: {
            prefetch.push(Services.prefetchCategories({ queryClient }));
            prefetch.push(Services.prefetchBrands({ queryClient, brandCategory: '', query: '' }));
            break;
        }

        case UmbracoTypes.PageTypes.IP31CategoryListPage: {
            prefetch.push(Services.prefetchNavigation({ locale, slug, queryClient }));
            break;
        }

        case UmbracoTypes.PageTypes.IP40ProductListPage:
        case UmbracoTypes.PageTypes.IP41CategoryNode: {
            const filterDefaults = await Services.prefetchFilterDefaults({ queryClient });
            prefetch.push(
                Services.prefetchNavigation({ locale, slug, queryClient }),
                Services.prefetchFilterEntities({
                    breadcrumb: page?.breadcrumb,
                    filterAdmin: page?.pageProperties?.filterAdmin,
                    filterDefaults,
                    pageTitle: page?.title,
                    queryClient,
                }),
            );
            break;
        }

        case UmbracoTypes.PageTypes.IP50ProductDetailPage: {
            const itemNo = decodeURIComponent(page?.pageProperties?.productItemNumber || '');
            const { product, errorType } = await Services.prefetchProduct({ itemNo, queryClient });

            if (errorType === 'ProductItemNotFoundException') {
                notFound = true;
                break;
            }

            prefetch.push(Services.prefetchRelatedEntities({ queryClient }, itemNo));
            prefetch.push(Services.prefetchComplementaryEntities({ queryClient }, itemNo));

            const productIsObsolete =
                product?.item?.itemStatus === ShopTypes.ItemStatusValues.Obsolete;

            if (!productIsObsolete) {
                prefetch.push(
                    Services.prefetchPrices({
                        ids: [itemNo],
                        queryClient,
                    }),
                );
            }

            break;
        }

        case UmbracoTypes.PageTypes.IP140NotFoundPage: {
            notFound = true;
            break;
        }

        case UmbracoTypes.PageTypes.IP150ErrorPage: {
            // If there is an error from page endpoint we manually throw an error, the last successfully generated page will then continue to show
            if (slug?.[0] !== '500') {
                throw new Error(`Failed to fetch page "${asPath}"`);
            }
            break;
        }

        default:
            break;
    }

    if ((page as UmbracoTypes.IPages)?.statusCode === 404) {
        notFound = true;
    }

    // Wait for all prefetch to complete
    await Promise.all(prefetch);

    return { page, notFound };
}

interface DataResolver {
    queryClient: QueryClient;
    locale: string | undefined;
    slug: string[] | undefined;
}
